* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'sans-serif';
  box-sizing: border-box;
  border: none;
  outline: none;
}

.container {
  height: 100%;
  width: 100%;
 
}



